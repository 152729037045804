<template>
  <div>
    <Loader v-if="isLoading" />
    <div v-else>
      <h3 style="margin-bottom: 25px">
        {{ $l.get("robot-create.header") }}
      </h3>
      <RobotForm :robot="robot" :onSubmit="onSubmit" />
    </div>
  </div>
</template>

<script>
import InitRobotCreateInteractor from "@/business/robot-create/init-robot-create";
import SubmitFormInteractor from "@/business/robot-create/submit-form";
import RobotCreateScreenController from "@/adapters/controllers/screen-robot-create";
import RobotForm from "@/application/components/robots/RobotForm";

import Loader from "@/application/components/Loader";

export default {
  screen_name: "robot-create",
  components: { RobotForm, Loader },
  data() {
    return {
      controller: null,
      interactors: {
        initRobotCreate: null,
        submitForm: null
      },
      isLoading: false,
      data: null,
      robot: {
        name: "",
        settingsHiddenLayerSize: 8,
        settingsTargetRate: 90,
        settingsTargetReset: 5000,
        settingsSpecificities: 0,
        settingsLearnRate: 0.05,
        settingsValidationSize: 0.1
      }
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(RobotCreateScreenController);

    //{ INTERACTORS
    this.interactors.submitForm = this.$injector.get(SubmitFormInteractor);
    this.interactors.initRobotCreate = this.$injector.get(
      InitRobotCreateInteractor
    );
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    this.interactors.initRobotCreate.handle();
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    onSubmit() {
      // console.log(this.robot);
      this.interactors.submitForm.handle(this.robot);
    }
  }
};
</script>

<style lang="scss"></style>
