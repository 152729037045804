<template>
  <div class="robot-form">
    <form @submit.prevent="() => {}">
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("dictionary.general-data") }}</h3>
        </div>
        <vs-input
          :label-placeholder="$l.get('robot-form.name-field', 'capitalize')"
          :danger="isNameInvalid"
          :danger-text="$l.get('errors.input-required', 'capitalize')"
          v-model="robot.name"
        />
        <vs-input
          :label-placeholder="
            $l.get('robot-form.layer-size-field', 'capitalize')
          "
          v-model="robot.settingsHiddenLayerSize"
          type="number"
        />
        <vs-input
          :label-placeholder="
            $l.get('robot-form.target-rate-field', 'capitalize')
          "
          v-model="robot.settingsTargetRate"
          type="number"
        />
        <vs-input
          :label-placeholder="
            $l.get('robot-form.target-reset-field', 'capitalize')
          "
          v-model="robot.settingsTargetReset"
          type="number"
        />
        <vs-input
          :label-placeholder="$l.get('robot-form.learn-rate', 'capitalize')"
          v-model="robot.settingsLearnRate"
          type="number"
        />
        <vs-input
          :label-placeholder="
            $l.get('robot-form.validation-percent', 'capitalize')
          "
          v-model="robot.settingsValidationSize"
          type="number"
        />
        <vs-select
          v-model="robot.settingsSpecificities"
          class="w-full select-large"
          :label="$l.get('robot-form.specificities-field')"
        >
          <vs-select-item
            :value="0"
            :text="$l.get('robot-form.specificities-type-none')"
          />
          <vs-select-item
            :value="1"
            :text="$l.get('robot-form.specificities-type-single')"
          />
        </vs-select>
      </vs-card>
    </form>
    <div style="margin-top: 25px; margin-bottom: 25px; text-align: right">
      <vs-button color="primary" type="filled" @click="onFormSubmit()">
        {{ $l.get("dictionary.submit-new") }}
      </vs-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "robot-form",
  props: ["robot", "onSubmit"],
  data() {
    return {
      isNameInvalid: false
    };
  },
  mounted() {},
  methods: {
    onFormSubmit() {
      this.isNameInvalid = this.robot.name.length == 0;
      if (this.isNameInvalid) {
        return;
      } else {
        this.onSubmit();
      }
    }
  }
};
</script>

<style lang="scss">
.robot-form .vs-con-input-label {
  width: 100% !important;
  margin-top: 25px;
}

.robot-form .criteria-node {
  cursor: pointer;
  padding: 0 2px;
  min-width: 60px;
  text-align: center;
  font-size: 11px;
  height: 30px;
  border: 2px solid #e8e8e3;
  border-radius: 2px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  background: #fff;
}

.robot-form .criteria-node:active {
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.1);
  margin: 1px 0 0 1px;
  border: 2px solid #d3d3cb;
}

.robot-form .criteria-node .node-name {
  line-height: 30px;
  color: #9b9b9b;
}

.robot-form .criteria-node:hover .node-name {
  color: #8b8b8b;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.15);
}

// .robot-form .vs-input {
//   margin: 15px;
// }
</style>
